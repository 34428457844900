.Instruction {
  max-width: 1440px;
  position: relative;
  margin: 0 auto;
}
.Instruction .bgInstr {
  width: 100%;
}
.Instruction .Instruction_01 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.Instruction .Instruction_01 h1 {
  position: absolute;
  text-align: center;
  width: 100%;
  margin: 70px 0;
  font-family: "Abhaya Libre ExtraBold";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 57px;
  background: radial-gradient(33.84% 1326.63% at 1% 78.26%, #E4E9FC 0%, #B69CF7 66.58%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.Instruction .Instruction_01 div {
  margin: 80px 0 0 0;
}
.Instruction .Instruction_01 div h4 {
  background: #D6D2FA;
  width: 150px;
  height: 89px;
  font-family: "Abhaya Libre Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 39.47%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: uppercase;
  -webkit-font-feature-settings: "pnum" on, "lnum" on;
          font-feature-settings: "pnum" on, "lnum" on;
  color: #554B79;
  padding: 0 0 0 30px;
  border-radius: 0 50px 50px 0;
}
.Instruction .Instruction_01 div p {
  font-family: "Abhaya Libre Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #FFFFFF;
  width: 373px;
}
.InstructionAbout.AboutPlatformFooter {
  position: absolute;
}
.Instruction .Instruction_01 div a {
  font-family: "Abhaya Libre Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  text-decoration-line: underline;
  color: #B69CF7;
}
.Instruction .Instruction_01 img {
  width: 700px;
  height: 430px;
  margin: 315px 0;
}
.Instruction .Instruction_02 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 100px 0;
}
.Instruction .Instruction_02 div h4 {
  background: #D6D2FA;
  width: 150px;
  height: 89px;
  font-family: "Abhaya Libre Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 39.47%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: uppercase;
  -webkit-font-feature-settings: "pnum" on, "lnum" on;
          font-feature-settings: "pnum" on, "lnum" on;
  color: #554B79;
  padding: 0 0 0 50px;
  border-radius: 50px 0 0 50px;
  float: right;
}
.Instruction .Instruction_02 div p {
  font-family: "Abhaya Libre Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #FFFFFF;
  width: 400px;
  margin: 200px 0;
}
.Instruction .Instruction_02 img {
  width: 500px;
  height: 430px;
}
.Instruction .Instruction_03 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.Instruction .Instruction_03 div h4 {
  background: #D6D2FA;
  width: 150px;
  height: 89px;
  font-family: "Abhaya Libre Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 39.47%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: uppercase;
  -webkit-font-feature-settings: "pnum" on, "lnum" on;
          font-feature-settings: "pnum" on, "lnum" on;
  color: #554B79;
  padding: 0 0 0 50px;
  border-radius: 50px 0 0 50px;
  float: right;
}
.Instruction .Instruction_03 div p {
  font-family: "Abhaya Libre Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #FFFFFF;
  width: 400px;
  margin: 200px 0;
}
.Instruction .Instruction_03 img {
  width: 730px;
  height: 430px;
}
.Instruction .Instruction_04 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 100px 0;
}
.Instruction .Instruction_04 h4 {
  background: #D6D2FA;
  width: 150px;
  height: 89px;
  font-family: "Abhaya Libre Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 39.47%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: uppercase;
  -webkit-font-feature-settings: "pnum" on, "lnum" on;
          font-feature-settings: "pnum" on, "lnum" on;
  color: #554B79;
  padding: 0 0 0 30px;
  border-radius: 0 50px 50px 0;
}
.Instruction .Instruction_04 p {
  font-family: "Abhaya Libre Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #FFFFFF;
  width: 373px;
}
.Instruction .Instruction_05 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 0 125px 0;
}
.Instruction .Instruction_05 h4 {
  background: #D6D2FA;
  width: 150px;
  height: 89px;
  font-family: "Abhaya Libre Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 39.47%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: uppercase;
  -webkit-font-feature-settings: "pnum" on, "lnum" on;
          font-feature-settings: "pnum" on, "lnum" on;
  color: #554B79;
  padding: 0 0 0 30px;
  border-radius: 0 50px 50px 0;
}
.Instruction .Instruction_05 p {
  font-family: "Abhaya Libre Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #FFFFFF;
  width: 373px;
}

@media screen and (max-width: 1150px) {
  .Instruction .Instruction_02 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .Instruction .Instruction_02 div p {
    max-width: 100%;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    padding: 50px 0;
  }
  .Instruction .Instruction_02 img {
    width: 100%;
    height: 100%;
    margin: 0px 0;
  }
  .Instruction .Instruction_03 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .Instruction .Instruction_03 div p {
    max-width: 100%;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    padding: 50px 0;
  }
  .Instruction .Instruction_03 img {
    width: 100%;
    height: 100%;
    margin: 0px 0;
  }
  .Instruction .Instruction_04 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .Instruction .Instruction_04 div p {
    max-width: 100%;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    padding: 50px 0;
  }
  .Instruction .Instruction_04 img {
    width: 100%;
    height: 100%;
    margin: 0px 0;
  }
  .Instruction .Instruction_05 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .Instruction .Instruction_05 div p {
    max-width: 100%;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    padding: 50px 0;
  }
  .Instruction .Instruction_05 img {
    width: 100%;
    height: 100%;
    margin: 0px 0;
  }
}
@media screen and (max-width: 1350px) {
  .Instruction .Instruction_01 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .Instruction .Instruction_01 div p {
    max-width: 100%;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    padding: 50px 0;
  }
  .Instruction .Instruction_01 img {
    width: 100%;
    height: 100%;
    margin: 0px 0;
  }
}
@media screen and (max-width: 800px) {
  .Instruction {
    max-width: 100%;
  }
  .Instruction .Instruction_01 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    position: relative;
  }
  .Instruction .Instruction_01 h1 {
    font-size: 26px;
    margin: 35px 0;
    line-height: 41px;
  }
  .Instruction .Instruction_01 div h4 {
    margin: 50px 0;
    max-width: 100%;
    width: 100px;
    height: 45px;
    padding: 0 0 0 15px;
    font-size: 42px;
  }
  .Instruction .Instruction_01 div p {
    max-width: 100%;
    font-size: 16px;
    line-height: 119.47%;
  }
  .Instruction .Instruction_01 div a {
    font-size: 13px;
  }
  .Instruction .Instruction_01 img {
    width: 100%;
    height: 100%;
    margin: 0px 0;
  }
}