.RouterClick {
  color: #7e7b8e;
  text-decoration: none;
  position: relative;
  font-family: "Abhaya Libre Medium", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  min-width: 100%;
  padding: 15px 15px 7%;
}

.RouterClick:hover {
  color: #c4c2dc;
}

.RouterClick::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 3vh;
  width: 100%;
  height: 3px;
  background-color: #e4e9fc;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.2s ease-in-out;
}

.RouterClick:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.RouterClick2 {
  color: black;
  text-decoration: underline solid;
  position: relative;
  font-family: "Abhaya Libre Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  padding: 2vw;
}

.inactive {
  color: white;
  text-decoration: underline solid;
  position: relative;
  font-family: "Abhaya Libre Medium", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  padding: 2vw;
}

@media screen and (max-width: 1080px) {
  .RouterClick {
    color: #c6b8f8;
    text-decoration: none;
    transition: all 0.05s ease-out;
    transform: scale(1);
    display: flex;
    align-items: center;
  }
}
